import { ThemeProvider } from '@mui/material';
import Layout from 'src/components/Layout/Layout';
import mainTheme from 'src/themes/main';
import { Analytics } from '@vercel/analytics/react';

export default function App() {
  return (
    <ThemeProvider theme={mainTheme}>
      <Layout />
      <Analytics />
    </ThemeProvider>
  );
}

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
    white: Palette['primary'];
    black: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
    white?: PaletteOptions['primary'];
    black?: PaletteOptions['primary'];
  }
}

// @babel-ignore-comment-in-output Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
    white: true;
    black: true;
  }
}

const colors = {
  neutral: {
    lighter: '#E8DCDE',
    light: '#E1D0D4',
    main: '#D9C5C9',
    dark: '#A19295',
    darker: '#6C6164',
    contrastText: '#3F5436',
  },
  white: {
    lighter: '#F7F3F7',
    light: '#F4EFF5',
    main: '#F1EBF2',
    dark: '#B3AEB4',
    darker: '#797579',
    contrastText: '#0D0D0D',
  },
  primary: {
    lighter: '#98A990',
    light: '#778E6E',
    main: '#58734C',
    dark: '#3F5436',
    darker: '#283622',
    contrastText: '#F7F3F7',
  },
  secondary: {
    lighter: '#E9E0AA',
    light: '#E2D58C',
    main: '#DBCA6B',
    dark: '#A2954E',
    darker: '#6D6432',
    contrastText: '#3F5436',
  },
  black: {
    lighter: '#626262',
    light: '#353535',
    main: '#0D0D0D',
    dark: '#070707',
    darker: '#030303',
    contrastText: '#F7F3F7',
  },
};

export default colors;

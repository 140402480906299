import {
  Unstable_Grid2 as Grid,
  Box,
  Typography,
  useTheme,
  Paper,
  Container,
} from '@mui/material';
import { useSearchParam } from 'react-use';
import bottom from 'src/assets/images/flower-bottom_1x.png';
import top from 'src/assets/images/flower-top_1x.png';
import singleLeaf from 'src/assets/images/single_leaf.png';
import leavesTop from 'src/assets/images/leaves_top.png';
import leavesSide from 'src/assets/images/leaves_left.png';
import { GlobalStyles } from '@mui/material';
import typo from 'src/themes/mainTypography';

const Leaf = ({ sx }: any) => (
  <Box
    sx={{
      backgroundImage: `url('${singleLeaf}')`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      height: '24px',
      width: '24px',
      ...sx,
    }}
  />
);

const Dot = ({ sx }: any) => (
  <Box
    sx={{
      width: '.25em',
      height: '.25em',
      borderRadius: '100%',
      bgcolor: 'primary.lighter',
      margin: '.25em',
      ...sx,
    }}
  />
);

const Salam = ({ text, sx }: any) => (
  <Typography
    sx={{
      color: 'secondary.dark',
      fontStyle: 'italic',
      fontWeight: 'bold',
      textAlign: 'center',
      display: 'flex',
      flex: {
        xs: 'row',
      },
      alignItems: 'center',
      gap: {
        xs: '.5em',
      },
      paddingX: { xs: '1.5em' },
      fontSize: { lg: 30, xs: 25 },
      ...sx,
    }}
    variant="body1"
  >
    <Box
      component="span"
      sx={{
        color: 'secondary.light',
        position: 'relative',
        textAlign: 'right',
        flex: '1 0 0',
        minWidth: '25px',
        height: '100px',
        borderRight: '1px solid',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url('${leavesSide}')`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          height: {
            xs: '50px',
          },
          width: '50px',
          position: 'absolute',
          top: 'calc(50% - 25px)',
          right: 'calc(0% - 25px)',
        }}
      />
    </Box>
    <Box
      component="span"
      sx={{
        flex: '0 1 auto',
        fontFamily: ['"Dancing Script"', typo.fontFamily].join(','),
      }}
    >
      {text}
    </Box>
    <Box
      component="span"
      sx={{
        color: 'secondary.light',
        position: 'relative',
        textAlign: 'right',
        flex: '1 0 0',
        minWidth: '25px',
        height: '100px',
        borderLeft: '1px solid',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url('${leavesSide}')`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          height: {
            xs: '50px',
          },
          width: '50px',
          position: 'absolute',
          top: 'calc(50% - 25px)',
          left: 'calc(0% - 25px)',
          transform: 'rotateY(180deg)',
        }}
      />
    </Box>
  </Typography>
);

const Layout = () => {
  const nama = useSearchParam('nama') || 'Ibu/Saudari';
  const theme = useTheme();

  return (
    <>
      <GlobalStyles
        styles={{
          html: {
            height: '100%',
          },
          body: {
            padding: 0,
            margin: 0,
            minHeight: '100%',
            display: 'flex',
            flexDirection: 'column',
          },
          '#root': {
            backgroundColor: theme.palette.white.main,
            flex: '1 0 auto',
            minHeight: '100%',
            overflow: 'hidden',
          },
        }}
      />
      <Container maxWidth="md" sx={{ padding: 2 }}>
        <Grid
          sx={{
            height: '100%',
            backgroundColor: 'white.main',
            border: '3px solid',
            borderColor: 'primary.lighter',
            borderTopLeftRadius: '99vw',
            borderTopRightRadius: '99vw',
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 20px 30px',
          }}
          container
        >
          <Grid display="flex" flexDirection="column" position="relative">
            <Box
              position="relative"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Box
                sx={{
                  backgroundImage: `url('${top}')`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  maxWidth: {
                    xs: '100%',
                  },
                  height: {
                    xs: '25em',
                  },
                  width: {
                    xs: '25em',
                  },
                  position: 'absolute',
                  top: {
                    xs: '-5em',
                  },
                  left: {
                    xs: '-5em',
                    sm: '-3em',
                    md: '-2em',
                  },
                }}
              />
              <Salam
                sx={{
                  paddingTop: {
                    xs: '5em',
                    sm: '7em',
                  },
                }}
                text={
                  'Assalamualaikum Warahmatullahi Wabarakatuh' /* `ٱلسَّلَامُ عَلَيْكُمْ وَرَحْمَةُ ٱللَّٰهِ وَبَرَكاتُهُ` */
                }
              />
            </Box>
            <Box
              sx={{
                width: '100%',
                minHeight: '60%',
                fontStyle: 'italic',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '2em',
                  padding: {
                    xs: '0 1em',
                    sm: '0 3em',
                    md: '0 4em',
                  },
                  color: 'secondary.darker',
                }}
              >
                <Typography align="center" variant="body1" paddingTop="2em">
                  Dengan rahmat dan ridho Allah SWT, kami mengharap kehadiran{' '}
                  <b>{nama}</b> pada acara tasyakuran{' '}
                  <b>Walimatul Hamli (4 bulan)</b> dan <b>Walimatul Haji</b>{' '}
                  yang Insya Allah akan diselenggarakan pada:
                </Typography>

                <Paper
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1em',
                    backgroundColor: 'transparent',
                  }}
                  elevation={0}
                >
                  <Box
                    component="span"
                    sx={{
                      color: 'secondary.light',
                      position: 'relative',
                      textAlign: 'right',
                      width: '100%',
                      minHeight: '100px',
                      borderBottom: '1px solid',
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      sx={{
                        backgroundImage: `url('${leavesTop}')`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        height: {
                          xs: '95px',
                        },
                        width: '214px',
                        position: 'absolute',
                        bottom: '-30px',
                        left: 'calc(50% - 107px)',
                      }}
                    />
                  </Box>
                  <Box
                    padding="1em"
                    display="flex"
                    flexDirection="column"
                    alignItems="stretch"
                    gap=".5em"
                  >
                    {Object.entries({
                      Hari: 'Sabtu',
                      Tanggal: '13 Mei 2023',
                      Waktu: [
                        'Pukul 12.30 s.d. 15.00',
                        <br />,
                        'Pukul 13.00 dimulai Yasinan dan Tausiyah oleh Dr. Hj. Sahiyah, S.Pd.I., M.M.Pd.',
                        <br />,

                        <b>{'( Dimohon hadir tepat waktu )'}</b>,
                      ],
                      Tempat:
                        'Jalan Kemiri Kavling Blok F 17 no 3, Ciwaduk, Kec. Cilegon, Kota Cilegon, Banten 42415 (PT. Artapuri Digital Mediatama)',
                    }).map((i) => (
                      <Grid
                        container
                        flexDirection="row"
                        flexWrap="nowrap"
                        gap=".5em"
                      >
                        <Typography
                          color="primary.lighter"
                          flex="0 0 3em"
                          variant="body1"
                          sx={{
                            fontFamily: [
                              '"Dancing Script"',
                              typo.fontFamily,
                            ].join(','),
                          }}
                        >
                          {i[0]}
                        </Typography>
                        <Typography
                          color="primary.lighter"
                          variant="body1"
                        >{`:`}</Typography>
                        <Typography
                          minWidth="0"
                          fontWeight="bold"
                          color="primary.main"
                          variant="body1"
                        >
                          {i[1]}
                        </Typography>
                      </Grid>
                    ))}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'row' },
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: 700,
                      color: 'secondary.dark',
                      gap: {
                        xs: '1em',
                      },
                      borderTop: '1px dashed',
                      borderColor: 'secondary.main',
                      paddingTop: '1em',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '.5em',
                        flexDirection: 'row',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      <Leaf />
                      <Box>Yasinan</Box>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        gap: '.5em',
                        flexDirection: 'row',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      <Leaf />
                      <Box>Doa Bersama</Box>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        gap: '.5em',
                        flexDirection: 'row',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      <Leaf />
                      <Box>Tausiyah oleh Dr. Hj. Sahiyah, S.Pd.I., M.M.Pd.</Box>
                    </Box>
                  </Box>
                  <Box
                    component="span"
                    sx={{
                      color: 'secondary.light',
                      position: 'relative',
                      textAlign: 'right',
                      width: '100%',
                      minHeight: '100px',
                      borderTop: '1px solid',
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      sx={{
                        backgroundImage: `url('${leavesTop}')`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        height: {
                          xs: '95px',
                        },
                        width: '214px',
                        position: 'absolute',
                        top: '-30px',
                        left: 'calc(50% - 107px)',
                        transform: 'rotateZ(180deg) rotateY(180deg)',
                      }}
                    />
                  </Box>
                </Paper>
                <Typography align="center" variant="body1">
                  Merupakan suatu kehormatan dan kebahagiaan bagi kami apabila{' '}
                  <b>{nama}</b> berkenan hadir untuk memberikan doa restu.
                </Typography>
                <Typography align="center" variant="body1">
                  Atas kehadiran serta doa restu <b>{nama}</b> kami ucapkan
                  terima kasih.
                </Typography>
              </Box>
              <Salam
                sx={{
                  paddingTop: {
                    xs: '2em',
                  },
                }}
                text={'Waalaikumsalam Warahmatullahi Wabarakatuh'}
              />
            </Box>

            <Box
              sx={{
                position: 'relative',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: {
                  xs: 'flex-start',
                  sm: 'center',
                },
                justifyContent: {
                  xs: 'center',
                },
                paddingBottom: {
                  xs: '5em',
                  sm: 0,
                },
              }}
            >
              <Box
                sx={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  display: 'flex',
                  color: 'primary.dark',
                  textAlign: 'center',
                  paddingY: {
                    xs: '1em',
                    sm: '3em',
                  },
                  paddingTop: {
                    xs: '3em',
                  },
                  paddingX: {
                    xs: '.5em',
                  },
                  gap: '.5em',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontStyle: 'italic',
                      marginBottom: '1em',
                      fontFamily: ['"Dancing Script"', typo.fontFamily].join(
                        ','
                      ),
                    }}
                  >
                    Hormat Kami,
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: {
                      xs: 'column',
                      sm: 'row',
                    },
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '.5em',
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                    Affan Dhia Ardhiva
                  </Typography>
                  <Dot sx={{ display: { xs: 'none', sm: 'initial' } }} />
                  <Typography sx={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                    Ayu Indah Damayanti
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                    dan Segenap Keluarga
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  backgroundImage: `url('${bottom}')`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  maxWidth: {
                    xs: '100%',
                  },
                  height: {
                    xs: '15em',
                  },
                  width: {
                    xs: '15em',
                  },
                  position: 'absolute',
                  bottom: {
                    xs: '-2em',
                  },
                  right: {
                    xs: '-4em',
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Layout;

import { createTheme } from '@mui/material/styles';
import typography from './mainTypography';
import color from './mainColor';

const theme = createTheme({
  palette: color,
  typography,
});

export default theme;
